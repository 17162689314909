import React, { useState, useRef, useCallback, useEffect } from "react";
import { OrbitControls, useHelper } from "@react-three/drei";
import { DirectionalLightHelper } from "three";
import { Canvas, useFrame } from "@react-three/fiber";
import * as THREE from "three";

const Geometries = ({ max, values, midPosition }) => {
  const [isClicked, setIsClicked] = useState(false);

  const valuesRef = useRef();
  const maxRef = useRef();
  const fillRef = useRef();

  // Normalize data from sensors for visualization purpose
  const normalizeData = (data) => {
    const normalized = [];

    for (let i = 0; i < 10; i++) {
      // Add circle first element
      normalized.push(data[i]);
      if (i < 9) {
        for (let k = 1; k < 5; k++) {
          normalized.push(data[i + k * 19]);
        }

        let index = 18 - i;
        for (let k = 1; k < 6; k++) {
          normalized.push(data[index]);
          index += 19;
        }
        // Repeat circle first element
        normalized.push(data[i]);
      } else {
        // Repeat first point of the last internal circle
        for (let k = 0; k < 10; k++) {
          normalized.push(data[i]);
        }
      }
    }

    // Return normalized array
    return normalized.reverse();
  };

  // Draw max, values and fill geometries based on max and values data
  useEffect(() => {
    const maxGeometry = maxRef.current;
    const fillGeometry = fillRef.current;
    const valuesGeometry = valuesRef.current;

    const { attributes: maxAttributes } = maxGeometry;
    const { attributes: fillAttributes } = fillGeometry;
    const { attributes: valuesAttributes } = valuesGeometry;

    const maxPositions = maxAttributes.position.array;
    const fillPositions = fillAttributes.position.array;
    const valuesPositions = valuesAttributes.position.array;

    // Normalize data
    const normalizedMax = normalizeData(max);
    const normalizedFill = normalizeData(max);
    const normalizedValues = normalizeData(values);

    // Total vertex number
    const vertexCount = maxPositions.length / 3;

    // Draw max and fill silo perimeter
    for (let i = 0; i < vertexCount; i++) {
      const index = 3 * i;
      // Set Y for max geometry
      maxPositions[index + 2] = normalizedMax[i] * -1;
      // Set Y for fill geometry
      fillPositions[index + 2] = normalizedFill[i] * -1;
    }

    // Update max shape
    maxAttributes.position.needsUpdate = true;
    maxGeometry.computeVertexNormals();

    // Draw value surface
    for (let i = vertexCount - 1; i >= 0; i--) {
      const index = 3 * i;
      let move = 0;
      let targetValue = -normalizedValues[i];

      // Iterate until the target value is properly positioned
      while (true) {
        // Calculate the maximum negative value allowed at this position
        const maxNegative = -normalizedMax[i - move];

        // If the target value exceeds the maximum negative value
        if (targetValue <= maxNegative) {
          // Check if the center (last vertex) is reached
          if (i - move - 11 <= 0) {
            // If yes set the value to this point
            targetValue = maxNegative;
            break;
          } else {
            // Otherwise, to the next related vertex
            move += 11;
            targetValue = -normalizedValues[i - move];
          }
        } else {
          // If the vertex moved to another vertex update x and z
          if (move > 0) {
            valuesPositions[index] = valuesPositions[index - move * 3];
            valuesPositions[index + 1] = valuesPositions[index - move * 3 + 1];
          }
          // Update y
          valuesPositions[index + 2] = targetValue;
          break;
        }
      }
    }


    // Draw correct fill shape
    const fillStartIndex = valuesPositions.length - 33;
    for (let i = 0; i < 33; i++) {
      fillPositions[fillStartIndex + i] = valuesPositions[fillStartIndex + i];
    }

    // Update values and fill shape
    fillAttributes.position.needsUpdate = true;
    valuesAttributes.position.needsUpdate = true;
    fillGeometry.computeVertexNormals();
    valuesGeometry.computeVertexNormals();
  }, [max, values]);

  return (
    <group rotation={[0, 0, 0]} onClick={() => setIsClicked(!isClicked)}>
      {/* Values */}
      <mesh position={[0, midPosition, 0]} rotation={[(-1 * Math.PI) / 2, 0, 0]}>
        <ringGeometry ref={valuesRef} args={[0, 20, 10, 9]} />
        <meshLambertMaterial
          color={"#FFC200"}
          vertexColors={false}
          side={THREE.DoubleSide}
        />
      </mesh>

      {/* Fill */}
      <mesh position={[0, midPosition, 0]} rotation={[(-1 * Math.PI) / 2, 0, 0]}>
        <ringGeometry ref={fillRef} args={[0, 20, 10, 9]} />
        <meshLambertMaterial color={"#FFC200"} side={THREE.DoubleSide} />
      </mesh>

      {/* Max */}
      <mesh position={[0, midPosition, 0]} rotation={[(-1 * Math.PI) / 2, 0, 0]}>
        <ringGeometry args={[0, 21, 10, 9]} ref={maxRef} />
        <meshStandardMaterial
          color={"white"}
          opacity={0.5}
          transparent={true}
          side={THREE.DoubleSide}
          wireframe
        />
      </mesh>
    </group>
  );
};

const Scene = ({ max, values }) => {
  const directionalLightRef = useRef();
  const [midPosition, setMidPosition] = useState(0);

  // Find proportions where draw geometries and lights
  useEffect(() => {
    setMidPosition(((Math.max(...max) - Math.min(...max)) / 4) + Math.min(...max))
  }, [max])

  // useHelper(directionalLightRef, DirectionalLightHelper, 0.5, "white");
  return (
    <>
      <ambientLight intensity={0.8} />
      <directionalLight
        ref={directionalLightRef}
        position={[0, midPosition * 2, midPosition * 2]}
        intensity={0.8}
        color={"white"}
      />
      <OrbitControls
        enableZoom={true}
        minDistance={50}
        maxDistance={70}
        minPolarAngle={Math.PI / 4}
        maxPolarAngle={Math.PI / 2}
      />
      <Geometries max={max} values={values} midPosition={midPosition} />
    </>
  );
};

const Silo3D = ({ max, values }) => {
  return (
    <Canvas>
      <Scene max={max} values={values} />
    </Canvas>
  );
};

export default Silo3D;
