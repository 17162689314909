import React, { useState, useEffect } from "react";
import styles from "./Silo.module.css";
import { RxUpdate } from "react-icons/rx";
import { GiDroplets } from "react-icons/gi";
import { TbTemperature } from "react-icons/tb";
import { FaArrowRight } from "react-icons/fa";
import { FaRegCalendarXmark } from "react-icons/fa6";
import { MdModeEditOutline } from "react-icons/md";
import { BsDatabaseX } from "react-icons/bs";
import { PiFan, PiCubeDuotone } from "react-icons/pi";
import { RiTruckLine } from "react-icons/ri";
import { SwishSpinner } from "react-spinners-kit";
import { GiZigzagHieroglyph } from "react-icons/gi";
import { useStateContext } from "../contexts/ContextProvider";
import MultilineChart from "./MultilineChart";
import {
    getSilo,
    getSiloData,
    getSiloHistory,
    getSiloStockHistory,
    getUpdates,
} from "../services/apiService";
import EditForm from "./EditForm";
import SiloSvg from "./SiloSvg";
import Switch from "react-switch";
import Silo3D from "./Silo3D";

const Silo = ({ id, code, selectedSilo, onSiloSelected }) => {
    const { createAxiosInstance, offline, setOffline, formatTimestamp } =
        useStateContext();
    const [isExpanded, setIsExpanded] = useState(false);
    const [anagData, setAnagData] = useState(null);
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isChartLoading, setIsChartLoading] = useState(true);
    const [isUpdatesQueueLoading, setIsUpdatesQueueLoading] = useState(true);
    const [chartData, setChartData] = useState(null);
    const [stockChartData, setStockChartData] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [updatesQueue, setUpdatesQueue] = useState(null);
    const [tridimensional, setTridimensional] = useState(false);

    // const max = [
    //   0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 85, 75, 65, 55, 45, 35, 25, 15, 5,
    //   1, 11, 21, 31, 41, 51, 61, 71, 81, 91, 86, 76, 66, 56, 46, 36, 26, 16, 6,
    //   2, 12, 22, 32, 42, 52, 62, 72, 82, 92, 87, 77, 67, 57, 47, 37, 27, 17, 7,
    //   3, 13, 23, 33, 43, 53, 63, 73, 83, 93, 88, 78, 68, 58, 48, 38, 28, 18, 8,
    //   4, 14, 24, 34, 44, 54, 64, 74, 84, 94, 89, 79, 69, 59, 49, 39, 29, 19, 9,
    // ]

    // const max = [
    //   20.24, 21.94, 25.92, 29.14, 35.03, 41.97, 56.66, 62.70, 62.43, 61.67, 61.43, 61.71, 60.53, 55.12, 35.94, 27.41, 22.65, 18.88, 16.01,
    //   17.42, 18.88, 22.65, 23.95, 26.88, 31.63, 39.28, 53.84, 62.43, 62.67, 61.43, 60.73, 61.49, 59.82, 37.75, 36.07, 23.47, 21.94, 16.01,
    //   16.01, 18.88, 20.19, 22.22, 29.60, 33.51, 50.87, 55.81, 64.42, 62.67, 61.43, 60.73, 60.53, 58.88, 53.15, 30.01, 28.38, 22.70, 18.83,
    //   16.71, 18.11, 21.01, 23.08, 25.97, 36.33, 51.83, 56.79, 62.43, 62.67, 62.43, 61.71, 60.53, 56.06, 53.15, 38.66, 29.19, 20.41, 18.83,
    //   16.71, 18.11, 20.19, 24.81, 25.97, 31.63, 38.31, 61.71, 62.43, 62.67, 62.43, 60.73, 60.53, 59.82, 53.15, 38.66, 28.38, 22.70, 18.83,
    // ]

    // const values = [
    //   16.71, 22.70, 24.28, 30.01, 35.03, 47.60, 55.70, 57.78, 58.45, 57.67, 54.46, 54.82, 51.83, 49.48, 38.66, 30.01, 23.47, 18.11, 16.71,
    //   20.95, 22.70, 25.92, 28.28, 35.03, 52.30, 53.77, 59.74, 57.45, 56.67, 53.46, 52.85, 51.83, 49.48, 41.37, 30.01, 21.83, 20.41, 16.71,
    //   15.30, 22.70, 24.28, 31.74, 33.22, 52.30, 53.77, 59.74, 56.45, 56.67, 55.46, 53.84, 52.80, 41.97, 39.56, 30.01, 23.47, 18.11, 16.71,
    //   15.30, 21.94, 23.47, 30.87, 32.32, 41.97, 54.73, 58.76, 56.45, 55.67, 55.46, 55.81, 54.73, 48.54, 32.32, 30.01, 21.01, 19.64, 15.30,
    //   15.30, 21.94, 23.47, 26.55, 33.22, 52.30, 54.73, 59.74, 56.45, 55.67, 55.46, 55.81, 50.87, 50.42, 40.47, 30.01, 21.83, 19.64, 15.30,
    // ]

    const max = [
        0.5, 20, 25, 30, 35, 40, 45, 50, 55, 55, 55, 50, 45, 40, 35, 30, 25, 20,
        0.5, 0.5, 20, 25, 30, 35, 40, 45, 50, 55, 55, 55, 50, 45, 40, 35, 30,
        25, 20, 0.5, 0.5, 20, 25, 30, 35, 40, 45, 50, 55, 55, 55, 50, 45, 40,
        35, 30, 25, 20, 0.5, 0.5, 20, 25, 30, 35, 40, 45, 50, 55, 55, 55, 50,
        45, 40, 35, 30, 25, 20, 0.5, 0.5, 20, 25, 30, 35, 40, 45, 50, 55, 55,
        55, 50, 45, 40, 35, 30, 25, 20, 0.5,
    ];

    const values = [
        14, 13, 14, 14, 14, 14, 15, 14, 14, 18, 18, 20, 16, 16, 15, 15, 14, 13,
        15, 14, 14, 14, 18, 18, 14, 15, 15, 14, 18, 19, 20, 22, 21, 20, 19, 18,
        17, 16, 14, 18, 17, 18, 16, 14, 15, 15, 15, 19, 15, 18, 19, 19, 16, 18,
        18, 19, 16, 16, 16, 15, 15, 15, 15, 15, 14, 15, 18, 18, 20, 22, 21, 21,
        19, 18, 19, 16, 23, 23, 24, 20, 19, 18, 15, 15, 15, 19, 18, 20, 22, 21,
        20, 19, 19, 17, 17,
    ];

    const values_bkp = [
        4, 3, 4, 4, 4, 4, 5, 4, 4, 8, 8, 10, 6, 6, 5, 5, 4, 3, 5, 4, 4, 4, 3, 3,
        4, 5, 5, 4, 8, 9, 10, 12, 11, 10, 9, 8, 7, 6, 4, 2, 3, 3, 3, 4, 5, 5, 5,
        8, 9, 10, 12, 10, 10, 10, 8, 7, 6, 3, 3, 4, 4, 4, 4, 5, 4, 5, 8, 8, 10,
        12, 11, 11, 9, 8, 7, 6, 3, 3, 4, 4, 4, 4, 5, 5, 5, 9, 8, 10, 12, 11, 10,
        9, 9, 7, 7,
    ];

    // Fetch silo hot data
    const fetchData = async () => {
        try {
            const api = await createAxiosInstance();
            const resSiloData = await getSiloData(api, id);
            setData(resSiloData[0]);

            setAnagData(await getSilo(api, id));
            setChartData(await getSiloHistory(api, id));
            setStockChartData(await getSiloStockHistory(api, id));
            setOffline(false);
        } catch (error) {
            console.log(error);
            setOffline(true);
            // Clear charts
            setChartData([]);
            setStockChartData([]);
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch silo data on component rendering and evry (10) seconds
    useEffect(() => {
        fetchData();

        const intervalId = setInterval(() => {
            fetchData();
        }, 10000);

        return () => {
            // Remove interval when componente dismount
            clearInterval(intervalId);
        };
    }, []);

    // Fetch if change editmode
    useEffect(() => {
        // Fetch silo anag data
        const fetchAnagData = async () => {
            try {
                const api = await createAxiosInstance();
                const anag = await getSilo(api, id);

                setAnagData(anag);
            } catch (error) {
                console.log(error);
            }
        };

        // Fetch updates
        const fetchUpdates = async () => {
            try {
                const api = await createAxiosInstance();
                const updates = await getUpdates(api, id);

                setUpdatesQueue(updates);
                setIsUpdatesQueueLoading(false);
            } catch (error) {
                console.log(error);
                setIsUpdatesQueueLoading(false);
            }
        };

        if (!editMode && !offline) {
            fetchAnagData();
        } else if (editMode) {
            setIsUpdatesQueueLoading(true);
            fetchUpdates();
        }
    }, [editMode]);

    // Fetch silo history data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const api = await createAxiosInstance();
                setChartData(await getSiloHistory(api, id));
                setStockChartData(await getSiloStockHistory(api, id));
                setIsChartLoading(false);
            } catch (error) {
                setOffline(true);
                setChartData([]);
            }
        };

        if (isExpanded) {
            setIsChartLoading(true);
            fetchData();
        }
    }, [isExpanded]);

    const handleSwitchChange = (tridimensional) => {
        setTridimensional(tridimensional);
    };

    // Check render conditions
    if (selectedSilo !== null && selectedSilo !== code) {
        return null;
    }

    return (
        <div
            className={`${styles["silo-container"]} ${
                isExpanded ? styles["expanded"] : ""
            }`}
        >
            <div className={styles["silo-navbar"]}>
                <div>
                    <h2>{anagData?.name.toUpperCase()}</h2>
                    <h4>#{code}</h4>
                </div>
                <div className={styles["silos-buttons-container"]}>
                    {isExpanded && (
                        <MdModeEditOutline
                            className={styles["expand-icon"]}
                            onClick={() => setEditMode(!editMode)}
                        />
                    )}
                    {data !== null && data !== undefined && (
                        <FaArrowRight
                            className={`${styles["expand-icon"]} ${
                                isExpanded ? styles["rotated"] : ""
                            }`}
                            onClick={() => {
                                if (isExpanded === false) {
                                    onSiloSelected(code);
                                    setIsExpanded(true);
                                } else {
                                    onSiloSelected(null);
                                    setEditMode(false);
                                    setIsExpanded(false);
                                }
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={styles["section-splitter"]}>
                <div
                    className={
                        isLoading
                            ? `${styles["section-preview-loading"]}`
                            : `${styles["section-preview"]}`
                    }
                >
                    {isLoading ? (
                        <SwishSpinner size={50} />
                    ) : (
                        <>
                            {" "}
                            <div className={styles["preview-data-splitter"]}>
                                {/* Hot data */}
                                <div className={styles["data-container"]}>
                                    <div className={styles["data-element"]}>
                                        <GiDroplets
                                            className={styles["data-icon"]}
                                        />
                                        <p>
                                            {data?.internalHumidity ?? "Nan"}%
                                        </p>
                                    </div>
                                    <div className={styles["data-element"]}>
                                        <TbTemperature
                                            className={styles["data-icon"]}
                                        />
                                        <p>
                                            {data?.internalTemperature ?? "Nan"}
                                            °C
                                        </p>
                                    </div>
                                    <div className={styles["data-element"]}>
                                        <FaRegCalendarXmark
                                            className={styles["data-icon"]}
                                        />
                                        <p>
                                            {data && data.outOfStock
                                                ? formatTimestamp(
                                                      data.outOfStock,
                                                      "",
                                                      "dd/MM/yy"
                                                  )
                                                : "NaN"}
                                        </p>
                                        <p
                                            className={
                                                styles["reliability-level"]
                                            }
                                        >
                                            {data?.predictionError &&
                                                (data?.predictionError > 0.2
                                                    ? "incerto"
                                                    : data?.predictionError >
                                                      0.05
                                                    ? "affidabile"
                                                    : "preciso")}
                                        </p>
                                    </div>
                                    <div
                                        className={
                                            styles[
                                                "tridimensional-toggle-container"
                                            ]
                                        }
                                    >
                                        <PiCubeDuotone
                                            className={
                                                styles["tridimensional-icon"]
                                            }
                                        />
                                        <p style={{ fontSize: 13 }}>3D</p>
                                        <Switch
                                            className={
                                                styles["tridimensional-switch"]
                                            }
                                            onChange={handleSwitchChange}
                                            checked={tridimensional}
                                            onColor='#ffc400'
                                            handleDiameter={18}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow='0px 1px 4px rgba(0, 0, 0, 0.6)'
                                            activeBoxShadow='0px 0px 1px 6px rgba(0, 0, 0, 0.2)'
                                            height={10}
                                            width={31}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={
                                        styles["animated-icons-container"]
                                    }
                                >
                                    {data?.bypass ? (
                                        <>
                                            <PiFan
                                                className={`${styles["animated-icon"]} ${styles["bypass"]}`}
                                            />
                                            <GiZigzagHieroglyph
                                                className={`${styles["animated-icon"]} ${styles["bypass"]}`}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <PiFan
                                                className={`${
                                                    styles["animated-icon"]
                                                } ${
                                                    data?.fanActive === 1 &&
                                                    styles["vent-running"]
                                                }`}
                                            />
                                            <GiZigzagHieroglyph
                                                className={`${
                                                    styles["animated-icon"]
                                                } ${
                                                    data?.engineActive === 1 &&
                                                    styles["vibration-running"]
                                                }`}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            {tridimensional ? (
                                <div
                                    className={
                                        styles["tridimensional-container"]
                                    }
                                >
                                    <Silo3D
                                        values={data?.points_map}
                                        max={max}
                                    />
                                </div>
                            ) : (
                                <div className={styles["svg-container"]}>
                                    {/* Sleep container */}
                                    {data?.sleep === 1 && (
                                        <div
                                            className={
                                                styles["sleep-container"]
                                            }
                                        >
                                            <div
                                                className={`${styles["zzz"]} ${styles["zzz-zzz"]}`}
                                            >
                                                Z
                                            </div>
                                            <div
                                                className={`${styles["zzz"]} ${styles["zzz-zz"]}`}
                                            >
                                                Z
                                            </div>
                                            <div
                                                className={`${styles["zzz"]} ${styles["zzz-z"]}`}
                                            >
                                                Z
                                            </div>
                                        </div>
                                    )}
                                    <SiloSvg
                                        code={anagData?.code}
                                        minStock={anagData?.stock?.min}
                                        data={data}
                                        max_values={anagData?.distances?.max}
                                        offset_values={
                                            anagData?.distances?.offsets
                                        }
                                        onSiloSelected={onSiloSelected}
                                        setIsExpanded={setIsExpanded}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
                {/* Expanded section */}
                {isExpanded && (
                    <div className={styles["section-full-data"]}>
                        {editMode ? (
                            isUpdatesQueueLoading ? (
                                <div
                                    className={
                                        styles["loading-update-container"]
                                    }
                                >
                                    <SwishSpinner size={50} />
                                </div>
                            ) : updatesQueue === null ||
                              updatesQueue.length === 0 ? (
                                <EditForm
                                    id={id}
                                    code={code}
                                    name={anagData?.name}
                                    content={anagData?.content}
                                    description={anagData?.description}
                                    supplier={anagData?.supplier}
                                    sleepMode={anagData?.sleepMode}
                                    fan={anagData?.fan}
                                    vibration={anagData?.vibration}
                                    stock={anagData?.stock}
                                    log={anagData?.log}
                                    setEditMode={setEditMode}
                                />
                            ) : (
                                <div
                                    className={
                                        styles["update-message-container"]
                                    }
                                >
                                    <h4 className={styles["update-message"]}>
                                        Silos in aggiornamento
                                    </h4>
                                    <RxUpdate
                                        className={styles["update-icon"]}
                                    />
                                    <p>
                                        Un aggiornamento è già in corso, riprova
                                        più tardi
                                    </p>
                                </div>
                            )
                        ) : (
                            <div
                                className={
                                    styles["section-full-data-info-container"]
                                }
                            >
                                <h3>
                                    Contenuto: <span>{anagData?.content}</span>
                                </h3>
                                <p>{anagData?.description}</p>
                                <p>
                                    <RiTruckLine />
                                    <span>{anagData?.supplier}</span>
                                </p>
                                <br />
                            </div>
                        )}

                        {/* Grafici */}
                        {!editMode && (
                            <div
                                className={
                                    styles["section-full-data-graph-container"]
                                }
                            >
                                <div className={styles["chart-container"]}>
                                    <h3>Conservazione [12h]:</h3>
                                    {chartData?.length > 0 ? (
                                        isChartLoading ? (
                                            <div
                                                className={
                                                    styles["chart-loading"]
                                                }
                                            >
                                                <SwishSpinner size={50} />
                                            </div>
                                        ) : (
                                            <MultilineChart data={chartData} />
                                        )
                                    ) : (
                                        <div className={styles["chart-empty"]}>
                                            <p>Dati insufficienti</p>
                                            <BsDatabaseX
                                                className={
                                                    styles["chart-empty-icon"]
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className={styles["chart-container"]}>
                                    <h3>Svuotamento [30 g]:</h3>
                                    {stockChartData?.length > 0 ? (
                                        isChartLoading ? (
                                            <div
                                                className={
                                                    styles["chart-loading"]
                                                }
                                            >
                                                <SwishSpinner size={50} />
                                            </div>
                                        ) : (
                                            <MultilineChart
                                                data={stockChartData}
                                            />
                                        )
                                    ) : (
                                        <div className={styles["chart-empty"]}>
                                            <p>Dati insufficienti</p>
                                            <BsDatabaseX
                                                className={
                                                    styles["chart-empty-icon"]
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Silo;
